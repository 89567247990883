.supplier{
    display: inline-block;
}

.supplier img{
    width:  250px;
    height: 250px;
    gap: 220px;
}

.suppliers{
    margin: 30px 0;
    display: inline-flex;
    gap: 100px;
}
.supplier p{
    margin: 10px 0;
}

.home p{
    width: 60%;
    margin-left: 20%;
}

.titleText{
    font-size: 2.4rem;
    text-shadow: 0px 0px 9px rgba(59, 60, 68, 1);
}

.midText{
    
    font-size: 1,8rem;
    text-shadow: 0px 0px 9px rgba(59, 60, 68, 1);
}
@media screen and (max-width:668px){
    .supplier{
      display: block;
      width: 100%;
    }
    .suppliers{
        display: block;
        width: 100%;
      }
}