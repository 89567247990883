.footer {   
    padding: 5px;
    background-color: rgb(24, 24, 24);
    color: white;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6vw;
    
    
}

.contact, .onas, .hours {
    display: inline-block;
    width: 35%;
    padding: 15px 0;
    max-width: 250px;
}
.fa{
    transform: translate(-3px, 1px);
    background-color: rgba(120, 120, 120, 0.8);
    
    border-radius: 30%;
}


.contact div {
   
    margin: 10px 0 ;
}
.contact div a{
    color: white ;
    text-decoration: none;
}
.contact div:hover, .contact div a:hover    {
    color: rgb(114, 114, 255);
}


@media screen and (max-width:680px){
    .onas{
      display: none;
    }
    .footer{
        width: 100vw;
        max-width:100%;  /* added */
        padding: 15px 0;
        display: block;
    }
   
    .contact, .hours {
        display: block;
       width: 100%;
        max-width: none;
        padding: none;
        margin: none;
    }
    .contact{
        padding-bottom: 20px;

    }
  }