.boxProduct{
    width: 15vw;
    height: 15vw;
    display: inline-flex;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    
  -webkit-box-shadow: 0px 0px 41px -3px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 41px -3px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 25px -3px rgba(66, 68, 90, 1);
  margin: 15px;
}

.boxName{
    
    font-size: 20px;
    font-weight: 600;   
    text-align: center;
}

.boxProduct img{
    margin: 0 auto;
    width: 8vw;
    height: 8vw;
}

/* .flexBoxRow{
    
    display: flex;
    flex-direction: row;    
    width: 100%;
} */

.moreButton{
    cursor: pointer;
    padding: 10px 0;
    background-color: rgb(21, 159, 239);
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 22px;
    width: 150px;
    height: 60px;
    text-decoration: none  !important ;
    margin: 20px 0 40px 0;

}

.moreButton a{
color: white;
text-decoration: none;
}

.moreButton:hover{
    
    background-color: rgb(18, 130, 195);
}