* {
  transition: .1s;
}


body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.topnav {
  z-index: 1000000;
  padding-left: 10%;
  overflow: hidden;
  background-color: rgb(251, 251, 251);
  -webkit-box-shadow: 0px 0px 41px -3px rgba(66, 68, 90, 1);
-moz-box-shadow: 0px 0px 41px -3px rgba(66, 68, 90, 1);
box-shadow: 0px 0px 41px -3px rgba(66, 68, 90, 1);
}

.topnav a {
  float: left;
  display: block;
  color: #050505;
  font-weight: 500;
  text-align: center;
  padding: 22px 20px;
  text-decoration: none;
  font-size: 20px;
}

.prod{
  display: block;
  float: right;
  font-size: 35px;
  width: 50px;
  border-radius: 10px;
  margin-right: 15px;
  margin-top: 9px;
  cursor: pointer;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  text-decoration: underline;
  color: black;

}

.topnav .icon {
  display: none;
  cursor: pointer;
}

.topnav .icon span {
  background-color: white;
  width: 20px;
  height: 5px;
  display: block;
  margin: 4px 0;
  
}

.disp{
  display: block;
}

.none {
  display: none;
}


.background{
  z-index: 100;
  background:
        /* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(255, 255, 255, 0.3), 
          rgba(255, 255, 255, 0.3)
        ),
        /* bottom, image */
        url("./tlo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover ;
  padding:40vh 4vw;
  margin-bottom: 10vh;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child){
    display: none;
  }

  .topnav a.icon {
    width: 20px;
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .background{
    
  padding:20vh 4vw;
  font-size: 1rem;
  }
}
