@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


body,
* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
    transition: .4s !important; 
}



.products {
    width: 90vw;
    margin-left: 5vw;
    box-sizing: border-box;
    /* background-color: rgb(241, 241, 241); */
    padding: 35px;
    height: fit-content;
    border-radius: 5px;
    position: relative;
    display: block;
    justify-content: center;
  
}

.product{
    box-sizing: border-box;
    background-color: rgb(220, 220, 220);
    padding: 25px;
    border-radius: 5px;
    flex: 1 0 21%; /* explanation below */
    -webkit-box-shadow: 0px 0px 41px -3px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 41px -3px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 10px -3px rgba(66, 68, 90, 1);
  margin: 15px auto;
    display: flex;
    max-width: 900px;
    justify-content: center;
    position: relative;
}


.description{
    padding: 20px;
}


.left{
    box-sizing: border-box;
    width: 28%;
    margin-right: 10%;
    position: relative;
}



.name{
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0 0 10px 0;
    text-align: center;
}
.price{
    font-size: 1.2rem;
    text-align: center;
}

.order{
    padding: 10px 0;
    background-color: rgb(21, 159, 239);
    color: white;
    border: none;
    border-radius: 5px;
    position: absolute;
    width: 100%;
    bottom: 20px;
    text-decoration: none  !important ;
}

.order:hover{
    background-color: rgb(5, 122, 191);
    cursor: pointer;
}

.description{
    width: 50%;
   
}

.flex{
    display: flex;
    justify-content: center;
    width: 100%;
}
#search{
    padding: 15px 15px;
    width: 100vw;
    max-width: 400px;
    border-radius: 25px;
    background-color: rgb(216, 216, 216);
    color: rgb(103, 103, 103);
    border: none;

}

#search:hover{
    background-color: rgb(190, 190, 190);
    cursor: text;
}









@media screen and (max-width: 820px) {
    
    .left{
        width: fit-content;
    }
    
    .products{
        padding: 20px;
    }

    .product{
        padding: 20px;

        gap: 5vw;
    }
    a{
        text-decoration: none !important;
    }
}


@media screen and (max-width: 600px) {

        .order{
            display: block;
            position: relative;
            bottom: 0;
            margin-top: 10px;
        }

        a{
            text-decoration: none !important;
        }
        .product{
            display: block;
        }

        .description{
            width: fit-content;
        }

        .left{
            width: 100%;
            display: block;
            text-align: center;
        }
}