.text-center{
    text-align: center;
}

.padding{
    padding: 22px;
}

.clickn{
    text-decoration: none;
    padding: 10px 8px ;
    background-color: rgb(21, 159, 239);
    color: white;
    border: none;
    border-radius: 5px;
}

.clickn:hover{
    background-color: rgb(20, 112, 172);
}

.map-responsive{

    overflow:hidden;

    padding-bottom:50.25%;
    

    position:relative;

    height:0;
    border-radius: 30px;
}

.map-responsive iframe{
    border-radius: 10px;
    left:10%;

    top:0;

    height:80%;

    width:80%;

    position:absolute;

}