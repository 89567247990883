
.banner{
    width: 100%;
    height: 30vh;
    background:
        /* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.3), 
          rgba(0, 0, 0, 0.3)
        ),
        /* bottom, image */
        url("../nav/tlo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover ;
  padding:20vh 5vw;
  font-size: 60px;
  line-height: 10px;
  font-weight: 600;
  box-sizing: border-box;
  margin-bottom: 2vh;
  color: white;
  text-align: left;
}

.openText{
  margin-top: 60px;
  font-size: 24px;
  padding-left: 40px;
  color: rgb(228, 228, 228);
}